import { isSameDay } from 'date-fns';

import {
  diffInDays,
  endOfDay,
  formatForceUTC,
  startOfDay,
  subDays,
  format,
} from './datetime';

export type Preset = {
  label: string;
  dates: [number, number];
};

export enum DatePreset {
  TODAY = 'today',
  YESTERDAY = 'yesterday',
  LAST7 = 'last7',
  LAST30 = 'last30',
  LAST90 = 'last90',
}

export type Presets = {
  [key in DatePreset]: Preset;
};

export const datePresets: Presets = {
  today: { label: 'Today', dates: [0, 0] },
  yesterday: {
    label: 'Yesterday',
    dates: [1, 1],
  },
  last7: {
    label: 'Last 7 days',
    dates: [7, 1],
  },
  last30: {
    label: 'Last 30 days',
    dates: [30, 1],
  },
  last90: {
    label: 'Last 90 days',
    dates: [90, 1],
  },
};

export const DATE_PRESET_DEFAULT = DatePreset.LAST30;

export function getSearchParams(searchParams: URLSearchParams) {
  return {
    from: searchParams.get('from'),
    to: searchParams.get('to'),
  };
}

function parseTimestamp(searchParams: URLSearchParams) {
  const { from, to } = getSearchParams(searchParams);

  if (from && to) {
    return {
      from: startOfDay(from),
      to: endOfDay(to),
    };
  }

  return {
    from: subDays(startOfDay(), datePresets[DATE_PRESET_DEFAULT].dates[0]),
    to: subDays(endOfDay(), datePresets[DATE_PRESET_DEFAULT].dates[1]),
  };
}

export function getDaysDiff(searchParams: URLSearchParams) {
  const { to, from } = parseTimestamp(searchParams);

  return diffInDays(to, from) + 1;
}

// for requests
export function generateTimespan(searchParams: URLSearchParams) {
  const { to, from } = parseTimestamp(searchParams);

  return JSON.stringify({
    lt: formatForceUTC(endOfDay(to)),
    gt: formatForceUTC(startOfDay(from)),
  });
}

export function generateTimerange(searchParams: URLSearchParams) {
  const { to, from } = parseTimestamp(searchParams);

  return {
    from: format(startOfDay(from), 'yyyy-MM-dd'),
    to: format(endOfDay(to), 'yyyy-MM-dd'),
  };
}

// for UI component
export const defaultTimespan = {
  start: subDays(startOfDay(), datePresets[DATE_PRESET_DEFAULT].dates[0]),
  end: subDays(endOfDay(), datePresets[DATE_PRESET_DEFAULT].dates[1]),
};

type GenTimeSpanProps = {
  from: string | null;
  to: string | null;
  presets?: Presets;
};

export const generateTimeSpanForSelector = ({
  from,
  to,
  presets = datePresets,
}: GenTimeSpanProps) => {
  if (from && to) {
    return {
      from: startOfDay(from),
      to: endOfDay(to),
    };
  }

  return {
    from: subDays(startOfDay(), presets[DATE_PRESET_DEFAULT].dates[0]),
    to: subDays(endOfDay(), presets[DATE_PRESET_DEFAULT].dates[1]),
  };
};

export function calculatePreset(start: Date, end: Date): DatePreset | null {
  const diff = diffInDays(end, start);

  const endIsYesterday = isSameDay(end, subDays(startOfDay(), 1));

  if (!endIsYesterday) {
    if (diff === 0 && isSameDay(start, startOfDay())) {
      return DatePreset.TODAY;
    }
    return null;
  }

  switch (diff) {
    case 89: {
      return DatePreset.LAST90;
    }
    case 29: {
      return DatePreset.LAST30;
    }
    case 6: {
      return DatePreset.LAST7;
    }
    case 0: {
      return DatePreset.YESTERDAY;
    }
    default: {
      return null;
    }
  }
}
