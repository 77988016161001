import {
  format as fnFormat,
  isAfter as fnIsAfter,
  isBefore as fnIsBefore,
  isSameDay as fnIsSameDay,
  addDays as fnAddDays,
  subDays as fnSubDays,
  subHours as fnSubHours,
  addHours as fnAddHours,
  addMinutes as fnAddMinutes,
  addYears as fnAddYears,
  parse as fnParse,
  getWeek as fnGetWeek,
  getMonth as fnGetMonth,
  startOfDay as fnStartOfDay,
  startOfToday,
  startOfMonth as fnStartOfMonth,
  startOfQuarter as fnStartOfQuarter,
  startOfYear as fnStartOfYear,
  endOfDay as fnEndOfDay,
  endOfToday,
  differenceInMinutes,
  differenceInCalendarDays,
} from 'date-fns';

type DateTime = Date | string | null;

export const toDate = (date: DateTime) => {
  if (date instanceof Date) return date;
  if (!date) return new Date();
  return new Date(date);
};

export const formatUTC = (date: DateTime) => toDate(date).toISOString();

// format as UTC but dont convert from local to actual UTC
export const formatForceUTC = (date: DateTime) =>
  fnFormat(toDate(date), "yyyy-MM-dd'T'HH:mm:ss'Z'");

export const format = (date: DateTime, formatString: string) =>
  fnFormat(toDate(date), formatString);

export const parse = (date: string, formatString: string) =>
  fnParse(date, formatString, new Date());

export const isAfter = (date: DateTime, dateToCompare: DateTime) =>
  fnIsAfter(toDate(date), toDate(dateToCompare));

export const isBefore = (date: DateTime, dateToCompare: DateTime) =>
  fnIsBefore(toDate(date), toDate(dateToCompare));

export const isSameDay = (date: DateTime, dateToCompare: DateTime) =>
  fnIsSameDay(toDate(date), toDate(dateToCompare));

export const subHours = (date: DateTime, count: number) =>
  fnSubHours(toDate(date), count);

export const addHours = (date: DateTime, count: number) =>
  fnAddHours(toDate(date), count);

export const addMinutes = (date: DateTime, count: number) =>
  fnAddMinutes(toDate(date), count);

export const addDays = (date: DateTime, count: number) =>
  fnAddDays(toDate(date), count);

export const subDays = (date: DateTime, count: number) =>
  fnSubDays(toDate(date), count);

export const addYears = (date: DateTime, count: number) =>
  fnAddYears(toDate(date), count);

export const diffInMinutes = (dateLeft: DateTime, dateRight: DateTime) =>
  differenceInMinutes(toDate(dateLeft), toDate(dateRight));

export const getWeek = (date: DateTime) => fnGetWeek(toDate(date));

export const getMonth = (date: DateTime) => fnGetMonth(toDate(date));

export const getMonthName = (date: DateTime) =>
  toDate(date).toLocaleString('default', { month: 'short' });

export const startOfDay = (date: DateTime = null) =>
  date === null ? startOfToday() : fnStartOfDay(toDate(date));

export const startOfMonth = (date: DateTime = null) =>
  fnStartOfMonth(date === null ? new Date() : toDate(date));

export const startOfQuarter = (date: DateTime = null) =>
  fnStartOfQuarter(date === null ? new Date() : toDate(date));

export const startOfYear = (date: DateTime = null) =>
  fnStartOfYear(date === null ? new Date() : toDate(date));

export const endOfDay = (date: DateTime = null) =>
  date === null ? endOfToday() : fnEndOfDay(toDate(date));

export const diffInDays = (date: DateTime, dateToCompare: DateTime) =>
  differenceInCalendarDays(toDate(date), toDate(dateToCompare));

export function formatDuration(duration: number) {
  if (duration <= 10) {
    return '< 10s';
  }

  const durationInSeconds = duration / 1_000_000_000;

  const hours = Math.floor(durationInSeconds / 3600);
  const minutes = Math.floor((durationInSeconds % 3600) / 60);
  const seconds = Math.floor(durationInSeconds % 60);

  return `${hours > 0 ? hours + 'h' : ''} ${minutes > 0 ? minutes + 'm' : ''} ${seconds}s`;
}
